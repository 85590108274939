/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Alert_codes } from '../models/alert-_codes';
import { App_versions } from '../models/app-_versions';
import { Calibration_fluids } from '../models/calibration-_fluids';
import { Experiments } from '../models/experiments';
import { Settings } from '../models/settings';

/**
 * Experiments configuration
 */
@Injectable({
  providedIn: 'root',
})
class ConfigService extends __BaseService {
  static readonly getAlertCodesPath = '/config/alert_codes';
  static readonly getAppVersionsPath = '/config/app/versions';
  static readonly getCalibrationFluidsPath = '/config/calibration_fluids';
  static readonly getExperimentsPath = '/config/experiments';
  static readonly getSettingsPath = '/config/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getAlertCodesResponse(XFields?: string): __Observable<__StrictHttpResponse<Alert_codes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/config/alert_codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alert_codes>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getAlertCodes(XFields?: string): __Observable<Alert_codes> {
    return this.getAlertCodesResponse(XFields).pipe(
      __map(_r => _r.body as Alert_codes)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getAppVersionsResponse(XFields?: string): __Observable<__StrictHttpResponse<App_versions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/config/app/versions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<App_versions>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getAppVersions(XFields?: string): __Observable<App_versions> {
    return this.getAppVersionsResponse(XFields).pipe(
      __map(_r => _r.body as App_versions)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getCalibrationFluidsResponse(XFields?: string): __Observable<__StrictHttpResponse<Calibration_fluids>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/config/calibration_fluids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Calibration_fluids>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getCalibrationFluids(XFields?: string): __Observable<Calibration_fluids> {
    return this.getCalibrationFluidsResponse(XFields).pipe(
      __map(_r => _r.body as Calibration_fluids)
    );
  }

  /**
   * @param params The `ConfigService.GetExperimentsParams` containing the following parameters:
   *
   * - `bits`: List of 0-based bitmap indexes (NOT test number)
   *
   * - `experiments`: List of experiment names (['A1', 'B1', 'K1', 'KSM1', 'KSM2', 'KSM3', 'W1', 'alkalinity', 'aluminum', 'ambient_temperature', 'ammonia', 'ammonium-nitrogen', 'arsenic', 'boron', 'cadmium', 'calc_magnesium', 'calcium', 'chlorine', 'chromium', 'conductivity', 'copper', 'dissolved_oxygen', 'fluoride', 'hardness', 'iron', 'lead', 'lsi', 'manganese', 'mercury', 'molybdenum', 'nickel', 'nitrates', 'nitrite', 'oxidation_reduction_potential', 'ph', 'phosphates', 'potassium', 'residual_chlorine', 'salinity', 'selenium', 'sg', 'silica', 'sodium', 'sulfate', 'sulfate-sulfur', 'total_dissolved_solids', 'un-ionized_ammonia', 'water_temperature', 'zinc'])
   *
   * - `methods`: List of methods (['a1', 'alkalinity', 'alkalinity_hr', 'b1', 'bb', 'bb_hr', 'calculated', 'k1', 'ksm', 'lab', 'w1', 'wb'])
   *
   * - `units`: List of units (['', 'PSU', 'mV', 'mg/L', 'mg/L CaCO3', 'ppb', 'ppm', 'uS/cm', '°F'])
   *
   * - `has_thresholds`: Has thresholds True/False
   *
   * - `tab`: Name of tab experiment appears in (['inorganics', 'lab', 'metals', 'nutrients', 'parameters'])
   *
   * - `experiment_types`: List of experiment types (['continuous', 'ksm_test', 'lab', 'oq', 'scheduled'])
   *
   * - `status`: List of statuses (['active', 'test']
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getExperimentsResponse(params: ConfigService.GetExperimentsParams): __Observable<__StrictHttpResponse<Array<Experiments>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.bits || []).forEach(val => {if (val != null) __params = __params.append('bits', val.toString())});
    (params.experiments || []).forEach(val => {if (val != null) __params = __params.append('experiments', val.toString())});
    (params.methods || []).forEach(val => {if (val != null) __params = __params.append('methods', val.toString())});
    (params.units || []).forEach(val => {if (val != null) __params = __params.append('units', val.toString())});
    if (params.hasThresholds != null) __params = __params.set('has_thresholds', params.hasThresholds.toString());
    if (params.tab != null) __params = __params.set('tab', params.tab.toString());
    (params.experimentTypes || []).forEach(val => {if (val != null) __params = __params.append('experiment_types', val.toString())});
    (params.status || []).forEach(val => {if (val != null) __params = __params.append('status', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/config/experiments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Experiments>>;
      })
    );
  }
  /**
   * @param params The `ConfigService.GetExperimentsParams` containing the following parameters:
   *
   * - `bits`: List of 0-based bitmap indexes (NOT test number)
   *
   * - `experiments`: List of experiment names (['A1', 'B1', 'K1', 'KSM1', 'KSM2', 'KSM3', 'W1', 'alkalinity', 'aluminum', 'ambient_temperature', 'ammonia', 'ammonium-nitrogen', 'arsenic', 'boron', 'cadmium', 'calc_magnesium', 'calcium', 'chlorine', 'chromium', 'conductivity', 'copper', 'dissolved_oxygen', 'fluoride', 'hardness', 'iron', 'lead', 'lsi', 'manganese', 'mercury', 'molybdenum', 'nickel', 'nitrates', 'nitrite', 'oxidation_reduction_potential', 'ph', 'phosphates', 'potassium', 'residual_chlorine', 'salinity', 'selenium', 'sg', 'silica', 'sodium', 'sulfate', 'sulfate-sulfur', 'total_dissolved_solids', 'un-ionized_ammonia', 'water_temperature', 'zinc'])
   *
   * - `methods`: List of methods (['a1', 'alkalinity', 'alkalinity_hr', 'b1', 'bb', 'bb_hr', 'calculated', 'k1', 'ksm', 'lab', 'w1', 'wb'])
   *
   * - `units`: List of units (['', 'PSU', 'mV', 'mg/L', 'mg/L CaCO3', 'ppb', 'ppm', 'uS/cm', '°F'])
   *
   * - `has_thresholds`: Has thresholds True/False
   *
   * - `tab`: Name of tab experiment appears in (['inorganics', 'lab', 'metals', 'nutrients', 'parameters'])
   *
   * - `experiment_types`: List of experiment types (['continuous', 'ksm_test', 'lab', 'oq', 'scheduled'])
   *
   * - `status`: List of statuses (['active', 'test']
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getExperiments(params: ConfigService.GetExperimentsParams): __Observable<Array<Experiments>> {
    return this.getExperimentsResponse(params).pipe(
      __map(_r => _r.body as Array<Experiments>)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getSettingsResponse(XFields?: string): __Observable<__StrictHttpResponse<Settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/config/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Settings>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getSettings(XFields?: string): __Observable<Settings> {
    return this.getSettingsResponse(XFields).pipe(
      __map(_r => _r.body as Settings)
    );
  }
}

module ConfigService {

  /**
   * Parameters for getExperiments
   */
  export interface GetExperimentsParams {

    /**
     * List of 0-based bitmap indexes (NOT test number)
     */
    bits?: Array<number>;

    /**
     * List of experiment names (['A1', 'B1', 'K1', 'KSM1', 'KSM2', 'KSM3', 'W1', 'alkalinity', 'aluminum', 'ambient_temperature', 'ammonia', 'ammonium-nitrogen', 'arsenic', 'boron', 'cadmium', 'calc_magnesium', 'calcium', 'chlorine', 'chromium', 'conductivity', 'copper', 'dissolved_oxygen', 'fluoride', 'hardness', 'iron', 'lead', 'lsi', 'manganese', 'mercury', 'molybdenum', 'nickel', 'nitrates', 'nitrite', 'oxidation_reduction_potential', 'ph', 'phosphates', 'potassium', 'residual_chlorine', 'salinity', 'selenium', 'sg', 'silica', 'sodium', 'sulfate', 'sulfate-sulfur', 'total_dissolved_solids', 'un-ionized_ammonia', 'water_temperature', 'zinc'])
     */
    experiments?: Array<string>;

    /**
     * List of methods (['a1', 'alkalinity', 'alkalinity_hr', 'b1', 'bb', 'bb_hr', 'calculated', 'k1', 'ksm', 'lab', 'w1', 'wb'])
     */
    methods?: Array<string>;

    /**
     * List of units (['', 'PSU', 'mV', 'mg/L', 'mg/L CaCO3', 'ppb', 'ppm', 'uS/cm', '°F'])
     */
    units?: Array<string>;

    /**
     * Has thresholds True/False
     */
    hasThresholds?: boolean;

    /**
     * Name of tab experiment appears in (['inorganics', 'lab', 'metals', 'nutrients', 'parameters'])
     */
    tab?: string;

    /**
     * List of experiment types (['continuous', 'ksm_test', 'lab', 'oq', 'scheduled'])
     */
    experimentTypes?: Array<string>;

    /**
     * List of statuses (['active', 'test']
     */
    status?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ConfigService }
