/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_downtimes } from '../models/shield-_downtimes';

/**
 * Shield Downtime related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldDowntimeService extends __BaseService {
  static readonly getShieldDowntimesPath = '/shield_downtime';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Shield Downtimes
   * @param params The `ShieldDowntimeService.GetShieldDowntimesParams` containing the following parameters:
   *
   * - `ids`: List of Shield Downtime ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `organization_ids`: List of organization ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldDowntimesResponse(params: ShieldDowntimeService.GetShieldDowntimesParams): __Observable<__StrictHttpResponse<Shield_downtimes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.shieldDeviceIds || []).forEach(val => {if (val != null) __params = __params.append('shield_device_ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_downtime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_downtimes>;
      })
    );
  }
  /**
   * Get Shield Downtimes
   * @param params The `ShieldDowntimeService.GetShieldDowntimesParams` containing the following parameters:
   *
   * - `ids`: List of Shield Downtime ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `organization_ids`: List of organization ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldDowntimes(params: ShieldDowntimeService.GetShieldDowntimesParams): __Observable<Shield_downtimes> {
    return this.getShieldDowntimesResponse(params).pipe(
      __map(_r => _r.body as Shield_downtimes)
    );
  }
}

module ShieldDowntimeService {

  /**
   * Parameters for getShieldDowntimes
   */
  export interface GetShieldDowntimesParams {

    /**
     * List of Shield Downtime ids
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    shieldDeviceIds?: Array<string>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldDowntimeService }
