/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Devices } from '../models/devices';

/**
 * Device related operations
 */
@Injectable({
  providedIn: 'root',
})
class DevicesService extends __BaseService {
  static readonly getDevicesPath = '/devices';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DevicesService.GetDevicesParams` containing the following parameters:
   *
   * - `ids`: List of device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_expires_at`: Start expires_at (ISO 8601)
   *
   * - `end_expires_at`: End expires_at (ISO 8601)
   *
   * - `is_deleted`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDevicesResponse(params: DevicesService.GetDevicesParams): __Observable<__StrictHttpResponse<Devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.startExpiresAt != null) __params = __params.set('start_expires_at', params.startExpiresAt.toString());
    if (params.endExpiresAt != null) __params = __params.set('end_expires_at', params.endExpiresAt.toString());
    if (params.isDeleted != null) __params = __params.set('is_deleted', params.isDeleted.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Devices>;
      })
    );
  }
  /**
   * @param params The `DevicesService.GetDevicesParams` containing the following parameters:
   *
   * - `ids`: List of device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_expires_at`: Start expires_at (ISO 8601)
   *
   * - `end_expires_at`: End expires_at (ISO 8601)
   *
   * - `is_deleted`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getDevices(params: DevicesService.GetDevicesParams): __Observable<Devices> {
    return this.getDevicesResponse(params).pipe(
      __map(_r => _r.body as Devices)
    );
  }
}

module DevicesService {

  /**
   * Parameters for getDevices
   */
  export interface GetDevicesParams {

    /**
     * List of device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * Start expires_at (ISO 8601)
     */
    startExpiresAt?: string;

    /**
     * End expires_at (ISO 8601)
     */
    endExpiresAt?: string;
    isDeleted?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DevicesService }
