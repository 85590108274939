import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
  Result_input,
  Shield_test,
  Shield_scheduled_test,
  Shield_threshold,
  Experiments
} from '../../../../../api/src/lib/models';
import {combineLatest, map, of, Subscription} from 'rxjs';
import {WaterSourceExtended} from '../../models/extensions/water-source-extended';
import {FormatResultValuePipe} from '../../pipes/format-result-value.pipe';
import {AppState, AppStateService} from '../../services/app-state.service';
import {AuthService} from '../../services/auth.service';
import {ExperimentConfigurationsService} from '../../services/experiment-configurations.service';
import {
  OrganizationCustomParamsSettingsCachedService
} from '../../services/organization-customParams-settings.cached.service';
import {ThresholdsCachedService} from '../../services/threshold-cached.service';
import {WaterSourcesCachedService} from '../../services/water-sources-cached.service';
import {SensorsBlacklistService} from '../../services/sensors-blacklist.service';
import { ConfigService } from '../../../../../api/src/lib/services/config.service';
import { ExtendedConfigService } from '../../../../../api/src/lib/extended-services/config.service';


export interface TestResultInterface {
  key: string;
  method: string;
  title: string;
  value: number;
  adl?: number;
  bdl?: number;
  unit?: string;
  status?: string;
  fallbackText?: string;
}

export interface ResultExtended extends Result_input {
  id?: string;
  sensor_id: number;
  test_number?: number;
  experiment: string;
  value: number;
  error_rate?: number;
  timestamp: string;
  updated_on: string;
  status?: string;
  title?: string;
  water_source_id?: number;
}

export interface CompletedTestVisualInterface extends Omit<Shield_test, 'end_time' | 'expected_end_time' | 'start_time' | 'updated_on'> {
  id: string;
  sensor_id?: number;
  water_source_id?: number;
  test_type?: string;
  start_time?: string;
  end_time?: string;
  status?: number;
  expected_end_time?: string;
  bitmap?: string;
  check_bitmap?: string;
  updated_on?: string;
  test_number?: number;
  metalsText?: string;
  statusText?: string;
  updated_by?: string;
}

@Component({
  selector: 'lib-test-results-modal',
  templateUrl: './test-results-modal.page.html',
  styleUrls: ['./test-results-modal.page.scss'],
  providers: [FormatResultValuePipe]
})
export class TestResultsModalPage implements OnInit {
  @Input() schedule: Shield_scheduled_test;
  @Input() results: ResultExtended[];
  @Input() selectedTestDetail: CompletedTestVisualInterface;
  @Input() partialResultsEnabled: boolean;
  testResult: TestResultInterface[];
  thresholdsDict: { [key: string]: Shield_threshold } = {};
  selectedSensor: WaterSourceExtended;
  appStateSub: Subscription
  appState: AppState;
  customParamSettings: Subscription;
  isCalcMagnesiumVisible: boolean;
  isLsiVisible: boolean
  isUnIonizedAmmoniaVisible: boolean;
  allExpSub: Subscription;
  allExperiments: Experiments[]

  constructor(private appStateService: AppStateService,
              private authService: AuthService,
              private waterSourcesCachedService: WaterSourcesCachedService,
              private experimentConfigurationsService: ExperimentConfigurationsService,
              private modalController: ModalController,
              private formatResultValuePipe: FormatResultValuePipe,
              private organizationCustomParamsSettingsCachedService: OrganizationCustomParamsSettingsCachedService,
              private sensorsBlacklistService: SensorsBlacklistService,
              private configService: ConfigService,
              private extendedConfigService: ExtendedConfigService
  ) {
  }

  ngOnInit() {

    this.organizationCustomParamsSettingsCachedService.getCached(false, true)
    this.customParamSettings = combineLatest([this.organizationCustomParamsSettingsCachedService.subject, this.extendedConfigService.getExperiments({})])
      .subscribe((data: any) => {
        const expMap: { [key: string]: Experiments } = {};
        console.log(data, 'data')
        this.isLsiVisible = data[0].isLsiEnabled;
        this.isCalcMagnesiumVisible = data[0].isMagnesiumEnabled;
        this.isUnIonizedAmmoniaVisible = data[0].isUnIonizedAmmoniaEnabled;

        const uniqueData = this.removeDuplicates(data[1], "experiment");
        // const uniqueData = data[1];
        this.allExperiments = uniqueData.filter(exp => exp.method !== 'lab' && exp.experiment !== 'ambient_temperature' && exp.method !== 'w1' && exp.method !== 'k1' && exp.method !== 'a1' && exp.method !== 'b1')
        if (this.results) {
          this.testResult = this.mapTestResults(this.results);
        }
        this.appStateSub = this.appStateService.getAppState()
          .subscribe(appstate => {
            this.appState = appstate
          })

        this.thresholdsDict = this.appStateService.thresholdsExternalService.getAllThresholdsForSensorId(this.schedule.water_source_id);

        this.testResult = this.testResult.sort((one, two) => {
          const metalValueOne = this.formatResultValuePipe.transform(one.value, one.key);
          const metalValueTwo = this.formatResultValuePipe.transform(two.value, two.key);
          const metalThresholdOne = this.thresholdsDict[one.key] ? this.formatResultValuePipe.transform(this.thresholdsDict[one.key], one.key) : 'N/A';
          const metalThresholdTwo = this.thresholdsDict[two.key] ? this.formatResultValuePipe.transform(this.thresholdsDict[two.key], two.key) : 'N/A';

          if (metalValueOne === 'N/A' && metalThresholdOne === 'N/A') {
            return 1;
          }
          if (metalValueTwo === 'N/A' && metalThresholdTwo === 'N/A') {
            return -1;
          }
          if (metalValueOne === 'N/A' || metalThresholdOne === 'N/A') {
            return 0;
          }
          return -1;
        });

        this.waterSourcesCachedService.getCached(true, false)
          .subscribe(waterSources => {
            if (this.schedule.water_source_id) {
              for (const waterSource of waterSources) {
                if (waterSource.id === this.schedule.water_source_id) {
                  this.selectedSensor = waterSource;
                  break;
                }
              }
            }
          });
      });

  }

  removeDuplicates(arr, prop) {
    const uniqueArray = [];
    const map = new Map();

    for (const obj of arr) {
      const key = obj[prop];
      if (!map.has(key)) {
        map.set(key, true);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  filterTestResults = (results: TestResultInterface[]): TestResultInterface[] => {
    if (this.sensorsBlacklistService.experimentsBlackdict?.[this.selectedTestDetail.sensor_id]) {
      const blackDict = this.sensorsBlacklistService.experimentsBlackdict?.[this.selectedTestDetail.sensor_id];
      return results.filter(res => {
        return !blackDict[res.key];
      })
    }
    return results;
  }

  filterExperimentsBitmapIdx(exp, bitmap?: string, disableFallback?: boolean) {
    if (exp?.sourceBitIdx?.sources && exp?.sourceBitIdx?.sources.length) {
      for (const bitmapIdx of exp?.sourceBitIdx?.sources) {
        if (bitmap && bitmap.charAt(bitmapIdx) === '1') {
          return exp.key;
        }
      }
      return null;
    }
    return disableFallback ? null : exp?.key;
  }

  private mapTestResults(results: ResultExtended[]): TestResultInterface[] {
    let testResults: TestResultInterface[] = this.allExperiments.reduce((acc, cur) => {
      // if (cur.key === 'calc_magnesium' && !this.appState?.orgSettings?.isMagnesiumEnabled) {
      if (cur.experiment === 'calc_magnesium' && !this.isCalcMagnesiumVisible) {
        return acc;
      }
      if (cur.experiment === 'lsi' && !this.isLsiVisible) {
        return acc;
      }
      if (cur.experiment === 'un-ionized_ammonia' && !this.isUnIonizedAmmoniaVisible) {
        return acc;
      }
      acc.push({value: null, key: cur.experiment, title: cur.title});
      return acc;
    }, []);

    testResults = testResults.filter(testResult => testResult?.key === this.filterExperimentsBitmapIdx(this.experimentConfigurationsService.experimentConfigurationsMap[testResult.key], this.selectedTestDetail?.bitmap));
    if (this.selectedTestDetail?.status !== 3 && this.selectedTestDetail?.bitmap && this.selectedTestDetail?.received_bitmap) {
      const processingBitmap = [...this.selectedTestDetail.bitmap].map((bit, idx) => {
        if (bit === '1' && this.selectedTestDetail.received_bitmap.charAt(idx) === '0') {
          return '1';
        }
        return '0';
      }).join('');
      testResults.filter(test => test?.key === this.filterExperimentsBitmapIdx(this.experimentConfigurationsService.experimentConfigurationsMap[test.key], processingBitmap, true))
        .map(testResult => {
          testResult.fallbackText = 'Processing result'
        })
    }

    const threshService: ThresholdsCachedService = this.appStateService.thresholdsExternalService;
    for (const testResult of testResults) {
      testResult.status = 'ok';
      if (this.experimentConfigurationsService.experimentConfigurationsMap[testResult.key]) {
        for (const result of results) {
          if (result.experiment === testResult.key) {
            testResult.value = result.value;
            testResult.method = result.method;
            testResult.bdl = result.bdl;
            testResult.adl = result.adl;
            testResult.status = threshService.getStatusForThresholdSensorIdAndExperimentByMinAndMax(result.value, result.water_source_id, result.experiment);
            break;
          }
        }
      }
    }
    return testResults;
  }

  ngOnDestroy(): void {
    if (this.appStateSub) {
      this.appStateSub.unsubscribe();
      this.appStateSub = null;
    }
    if (this.customParamSettings) {
      this.customParamSettings.unsubscribe();
    }
  }
}
